@media (max-width: 576px)
{
  .email-input {
    margin-bottom: 0.5rem;
  }
}


.smartrmail-embedded{
  width: 100%;
  height: 100%;
  padding: 20px 0;
  line-height: 1.5;
}
.smartrmail-embedded .row {
  margin-right: -15px;
  margin-left: -15px;
  width: 100%;
}
.smartrmail-embedded .horizontal{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.smartrmail-embedded .col-xs-4 {
  width: 33.3333333333%;
  position: relative;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}
.smartrmail-embedded .nolabels{
  display: flex;
  align-items: center;
}
.smartrmail-embedded .col-xs-offset-2 {
  margin-left: 16.6666666667%;
}
.smartrmail-embedded .col-xs-8 {
  width: 66.6666666667%;
  max-width: 66.6666666667%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.smartrmail-embedded .col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.smartrmail-embedded .wrap {
  text-align: center;
  width: 540px;
  margin: 0 auto;
}
.smartrmail-embedded .subscribe-form-logo img {
  height: auto;
  padding: 40px 0;
  margin-bottom: 0;
  width: 100%;
}
.smartrmail-embedded .subscribe-form-heading {
  font-weight: 700;
}
.smartrmail-embedded .subscribe-form{
  width: 100%;
}
.smartrmail-embedded .subscribe-form-body{
  display: flex;
  align-items: center;
  height: 100%;
}
.smartrmail-embedded .subscribe-form .subscribe-form-submit{
  background-color: #4ecca3;
  border: 2px solid #4ecca3;
  font-size: 15px;
}
.smartrmail-embedded .subscribe-form .subscribe-form-submit{
  display: block;
  margin: 0;
}

.smartrmail-embedded .subscribe-form-heading{
  font-size: 1.2rem;
}
.smartrmail-embedded .subscribe-form label{
  font-size: 1rem;
}
.smartrmail-embedded .subscribe-form-heading {
  font-weight: 700;
}
.smartrmail-embedded .subscribe-form-store-name {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 39px;
  font-size: 15px;
  font-style: bold;
  font-weight: 500;
}
.smartrmail-embedded .form-control {
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  font-size: 15px;
  line-height: 15px;
  color: #555555;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.smartrmail-embedded form .form-group input[type="text"] {
  height: auto;
  padding: 9.5px 12px;
  font: 15px "Lato";
}
.smartrmail-embedded *, ::after, ::before {
  box-sizing: border-box;
}
.smartrmail-embedded .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.smartrmail-embedded .btn-group-lg>.btn, .btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem;
}
.smartrmail-embedded .form-group {
  margin-bottom: 1rem;
}
@media (min-width: 768px){
.smartrmail-embedded .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
}
@media (min-width: 768px) {
.smartrmail-embedded .col-sm-4 {
    width: 33.3333333333%;
  }
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}
