.footer
{
  background: var(--brand-dark-shadow);
  color: #fff;
  padding: 1rem 0;
}

.footer .footerItem
{
  background-color: transparent;
  color: #fff;
  border: none;
}

.trustPilot
{
  margin-bottom: 1rem;
}

.trustPilot > iframe + iframe
{
  display: none !important;
}

.contactDetails
{
  color: var(--brand-light-grey);
}
