.heroImg
{
  height: 16rem;
  background-position: 60% center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/mountain_hero.gif');
}

.shopButton
{
  position: relative;
  top: calc(100% - 4rem);
  bottom: 0;
}

.shopButton > a, .shopButton > a:visited
{
  position: absolute;
  right: 2rem;
  color: var(--brand-main);
}

.intro
{
  background: var(--brand-main);
  padding: 3rem;
  font-size: 3rem;
  text-align: center;
  font-family: chlakh;
}

@media only screen and (max-width: 576px) {
  .intro
  {
    padding: 1.5rem;
    font-size: 1.5rem;
  }

  .heroImg
  {
    height: 12rem;
    background-image: url('/mountain_hero_mobile.gif');
  }
}


.emailSection
{
  background-color: var(--brand-dark);
  color: var(--brand-text);
  margin-top: -1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
