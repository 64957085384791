:root {
  --brand-main: rgba(78,204,163,1);
  --brand-highlight: rgba(47,255,132,1);
  --brand-dark: rgba(35,41,49,1);
  --brand-dark-shadow: rgba(57,62,70,1);
  --brand-light-grey: rgba(158,158,158,1);
  --brand-text: rgba(255,255,255,1) !important;
  --brand-main-focus: rgb(78,204,163,1);
  --brand-main-outline: rgb(78,204,163,1);
  --brand-main-shadow: rgb(78,204,163,0.5);

  --brand-font: chlakh;
}

@font-face {
  font-family: chlakh;
  src: url('/Chlakh.ttf');
}

@font-face {
  font-family: Lato;
  src: url('/Lato/Lato-Regular.ttf');
}

a, a:visited
{
  color: inherit;
}

a:hover, a:focus
{
  text-decoration: none;
  color: var(--brand-highlight);
}

.btn-primary
{
  color: var(--brand-dark);
  background-color: var(--brand-main);
  border-color: var(--brand-main);
}

.btn-primary:focus, .btn-primary:hover
{
  color: var(--brand-dark);
  background-color: var(--brand-main-focus);
  border-color: var(--brand-main-outline);
  box-shadow: 0 0 0 0.2rem var(--brand-main-shadow);
}

.btn-dark
{
  color: var(--brand-main);
  background-color: var(--brand-dark);
  border-color: var(--brand-dark);
}

.btn-dark:focus, .btn-dark:hover
{
  color: var(--brand-dark);
  background-color: var(--brand-main-focus);
  border-color: var(--brand-main-outline);
  box-shadow: 0 0 0 0.2rem var(--brand-main-shadow);
}

body
{
  font-family: 'Lato', sans-serif;
}

.osano-cm-widget > svg > path
{
  stroke: #000;
}

.osano-cm-widget > svg > g
{
  fill: var(--brand-highlight);
}

.onlyMobile
{
  display: none;
}

@media only screen and (max-width: 576px) {
  .onlyMobile
  {
    display: block;
  }
}
