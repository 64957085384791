.menuBar
{
  /*border: 1px solid var(--brand-main);*/
  background-color: var(--brand-dark);
  color: var(--brand-text);
}

.toggleIcon
{
  color: var(--brand-main);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(47,255,132,1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.brandName
{
  font-family: var(--brand-font);
  font-size: 1.8rem !important;
  color: var(--brand-text);
  margin-right: 3rem !important;
}

.navItem
{
  font-family: var(--brand-font);
  font-size: 1.5rem;
  color: var(--brand-text);
}
