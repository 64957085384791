.container {
  margin: auto;
  display: block;
}

.container path {
  will-change: transform;
}

.continents {
  /*animation: scroll 2s infinite;*/
}

@keyframes scroll
  from { transform: translate(-25px,-25px); }
  to { transform: translate(100px,0); }
