.page
{
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 4.2rem);
  padding-top: 4.2rem; */
}

.header
{
  height: 4.2rem;
}

.header, .footer
{
  flex-grow: 0;
}

.content
{
  flex-grow: 1;
  min-height: calc(100vh - 4.2rem);
  overflow-y: scroll;
}
