.heading {
  text-align: center;
  margin-bottom: 1rem;
  padding-top: 1rem;
  border-top: 4px solid var(--brand-main);
}

.col {
  margin-bottom: 1rem;
}

.image {
  width: 100%;
  height: 100%;
}
